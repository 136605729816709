
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      constInput: 3,
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      showCourseNotice: false,
      tableData: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
        },
        {
          name: "TI",
          key: "ti",
          sortable: true,
          width: "5px",
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "BN",
          key: "bn",
          sortable: true,
        },
        {
          name: "Trainee Count",
          key: "trainee_count",
          sortable: true,
        },
        {
          name: "Unit Cost",
          key: "unit_cost",
          sortable: true,
        },

        {
          name: "Pay %",
          key: "pay_percent",
          sortable: true,
        },
        {
          name: "Payment Amount	",
          key: "payment_amount",
          sortable: true,
        },
        {
          name: "Bill Amount	",
          key: "bill_amount",
          sortable: true,
        },
        {
          name: "New Unit Cost",
          key: "new_unit_cost",
          sortable: true,
        },
        {
          name: "New Payment Amount",
          key: "new_payment_amount",
          sortable: true,
        },
        {
          name: "New Bill Amount",
          key: "new_bill_amount",
          sortable: true,
        },
        {
          name: "Adj. Amount",
          key: "adj_amount",
          sortable: true,
        },
      ],
    };
  },
  // async created() {
  //   await this.getEntityTypes();
  //   await this.getEntityInfos();
  // },
  methods: {
    async formSubmit() {
      //form submit
    },
    // async getEntityInfos() {
    //   await ApiService.get("entity/infos")
    //     .then((response) => {
    //       this.entityInfos = response.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    // async getEntityTypes() {
    //   await ApiService.get("entity/types")
    //     .then((response) => {
    //       this.entityTypes = response.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    // courseInfo() {
    //   this.courseInfoData = true;
    // },
    courseNotice() {
      this.showCourseNotice = true;
    },
    // trainingProviderNotice() {
    //   this.showtrainingProviderNotice = true;
    // },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
